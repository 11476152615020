import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { addUtmTagsInUrls, setCookieFromUrl } from "../../widgets/cookie"

interface CaseStudiesProps {
  data: {
    contentfulBlogPost: {
      id: string
      title: string
      metaTitle: string
      metaDescription: string
      body: {
        raw: any

        references: {
          file: {
            url: string
            details: any
          }
          contentful_id: string
        }[]
      }
      bannerImage: {
        url: string
      }
      slug: string
      publishDate: string
      tags: string
    }
  }
  path: string
}

export default function Blog(props: CaseStudiesProps) {
  const { contentfulBlogPost } = props.data

  if (!props.data) return <div>empty</div>

  const options: any = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p style={{ whiteSpace: "pre-line" }}>{children}</p>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const imageID = node.data.target.sys.id
        const { file } = props.data.contentfulBlogPost.body.references.find(
          ({ contentful_id: id }) => id === imageID
        )
        return (
          <div className="images-align-center-half-width">
            <img src={file?.url} alt="banner" />
          </div>
        )
      },
    },
  }
  const p = {
    ...props,
    title: contentfulBlogPost?.metaTitle ?? "",
    description: contentfulBlogPost?.metaDescription ?? "",
  }

  React.useEffect(() => {
    setCookieFromUrl()
  }, [1])
  React.useEffect(() => {
    addUtmTagsInUrls({ links: document.querySelectorAll("a") })
  }, [])
  return (
    <Layout {...p}>
      <div className="mainContainer">
        <div
          className="container blog-post-body"
          style={{ display: "grid", placeItems: "center" }}
        >
          <div className="row">
            <div className="blog-post">
              <h1>{contentfulBlogPost.title}</h1>
              <img src={contentfulBlogPost.bannerImage?.url} alt="" />
              <div>
                {documentToReactComponents(
                  JSON.parse(contentfulBlogPost.body.raw),
                  options
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      body {
        raw
        references {
          file
          contentful_id
        }
      }
      slug
      metaTitle
      metaDescription
    }
  }
`
